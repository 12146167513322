"use client"

import React, { type ReactNode, useCallback } from "react"

import { DMButtonNeutral } from "@supernovaio/dm/src/components/DMButtons"

import { IconError, IconClose, IconCheckCircle } from "@supernovaio/icons"

import { DMIcon } from "../../components/DMIcon"

import { useDMToastsContext } from "./DMToastsContext"

import { type ToastProps, useToast } from "reshaped/bundle"

const SHORT_TIMEOUT = 3 * 1000
const LONG_TIMEOUT = 10 * 1000
const INFINITE_TIMEOUT = 0

export type ShowToastConfig = Pick<ToastProps, "icon" | "actionsSlot"> & {
  text: NonNullable<ReactNode>
  description?: NonNullable<ReactNode>
  /** Using presets will override some of passed props */
  preset?: "success" | "error"
  color?: "neutral" | "critical"
  /** Flag that dictates the toast to stay visible indefinitely.
   * The toast can still be hidden manually, using {@link hide} or {@link hideAll}. */
  isInfinite?: boolean
  /** Timeout override in milliseconds */
  timeout?: number
  /** Data test id for testing purposes */
  dataTestId?: string
}

// Global options are defined in DMProvider
export function useDMToast() {
  const toast = useToast()
  const { registerToast, unregisterToast, toastsIds } = useDMToastsContext()

  const show = useCallback(
    (toastConfig: ShowToastConfig) => {
      let timeout = SHORT_TIMEOUT
      let { icon, color } = toastConfig
      let toastId: string | undefined

      if (toastConfig.actionsSlot) {
        timeout = LONG_TIMEOUT
      }

      if (toastConfig.isInfinite) {
        timeout = INFINITE_TIMEOUT
      }

      const closeButton = (
        <DMButtonNeutral
          key="close-toast-button"
          icon={<DMIcon svg={IconClose} size="small" color="white" />}
          size="small"
          variant="ghost"
          className="px-4" // hack to make the button square-ish, can be removed when we will refactor Reshaped's useToast
          onClick={() => {
            if (toastId) {
              toast.hide(toastId)
            }
          }}
        />
      )

      let actionSlotWithCloseButton: React.ReactNode[]

      // Check if actionsSlot is there
      if (toastConfig.actionsSlot) {
        // if it's an array, add close button to it
        if (Array.isArray(toastConfig.actionsSlot)) {
          actionSlotWithCloseButton = [
            ...(toastConfig.actionsSlot as ReactNode[]).filter(Boolean), // null or undefined actions are filtered out, they're causing toasts to have error
            closeButton,
          ]
        } else {
          // for cases where it's not an array
          actionSlotWithCloseButton = [toastConfig.actionsSlot, closeButton]
        }
      } else {
        // if there are no actions, just add close button
        actionSlotWithCloseButton = [closeButton]
      }

      if (toastConfig.preset === "success") {
        timeout = SHORT_TIMEOUT
        icon = IconCheckCircle
        color = "neutral"
      } else if (toastConfig.preset === "error") {
        timeout = LONG_TIMEOUT
        icon = IconError
        color = "critical"
      }

      // override any timeout from above by passed timeout
      if (toastConfig.timeout) {
        timeout = toastConfig.timeout
      }

      toastId = toast.show({
        children: (
          <div
            className="flex flex-col gap-2"
            data-test-id={toastConfig.dataTestId}
          >
            <div className="text-body font-semibold">{toastConfig.text}</div>
            {toastConfig.description && (
              <div className="text-body-small font-semibold">
                {toastConfig.description}
              </div>
            )}
          </div>
        ),
        icon,
        color,
        className: "dm-toast", //  workaround for Reshaped toasts overrride before we refactor them to Tailwind
        position: "bottom",
        timeout,
        actionsSlot: actionSlotWithCloseButton,
        attributes: {
          "data-rs-theme": "darkmatter",
          "data-rs-color-mode": "dark",
        },
      })

      registerToast(toastId)

      return toastId
    },
    [registerToast, toast]
  )

  const hide = useCallback(
    (toastId: string) => {
      unregisterToast(toastId)

      return toast.hide(toastId)
    },
    [unregisterToast, toast]
  )

  const hideAll = useCallback(() => {
    toastsIds.forEach((toastId) => hide(toastId))
  }, [hide, toastsIds])

  return {
    show,
    hide,
    hideAll,
  }
}
