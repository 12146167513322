import { RGBColor } from "react-color"

import { toHex } from "color2k"

export const rgbToHex8 = ({ r, g, b, a = 1 }: RGBColor): string =>
  toHex(`rgba(${r}, ${g}, ${b}, ${a})`)

interface OklchColor {
  l: number;
  c: number;
  h: number;
  alpha?: number;
}

export function hexToOklch(hex: string): OklchColor {

  const hasAlpha = hex.length === 9;
  const alpha = hasAlpha ? parseInt(hex.slice(7, 9), 16) / 255 : 1;

  const r = parseInt(hex.slice(1, 3), 16) / 255;
  const g = parseInt(hex.slice(3, 5), 16) / 255;
  const b = parseInt(hex.slice(5, 7), 16) / 255;

  const linearR = r <= 0.04045 ? r / 12.92 : ((r + 0.055) / 1.055)**2.4;
  const linearG = g <= 0.04045 ? g / 12.92 : ((g + 0.055) / 1.055)**2.4;
  const linearB = b <= 0.04045 ? b / 12.92 : ((b + 0.055) / 1.055)**2.4;

  const x = 0.4124564 * linearR + 0.3575761 * linearG + 0.1804375 * linearB;
  const y = 0.2126729 * linearR + 0.7151522 * linearG + 0.0721750 * linearB;
  const z = 0.0193339 * linearR + 0.1191920 * linearG + 0.9503041 * linearB;

  const l = 0.8189330101 * x + 0.3618667424 * y - 0.1288597137 * z;
  const m = 0.0329845436 * x + 0.9293118715 * y + 0.0361456387 * z;
  const s = 0.0482003018 * x + 0.2643662691 * y + 0.6338517070 * z;

  const lCube = Math.cbrt(l);
  const mCube = Math.cbrt(m);
  const sCube = Math.cbrt(s);

  const L = 0.2104542553 * lCube + 0.7936177850 * mCube - 0.0040720468 * sCube;
  const a = 1.9779984951 * lCube - 2.4285922050 * mCube + 0.4505937099 * sCube;
  const b2 = 0.0259040371 * lCube + 0.7827717662 * mCube - 0.8086757660 * sCube;

  const C = Math.sqrt(a * a + b2 * b2);
  let h = Math.atan2(b2, a) * 180 / Math.PI;
  if (h < 0) h += 360;

  return {
    l: L,
    c: C,
    h,
    ...(hasAlpha && { alpha })
  };
}

export function oklchToHex({ l, c, h, alpha = 1 }: OklchColor): string {
  // Convert hue to radians
  const hRad = h * Math.PI / 180;
  
  // Convert to OKLab
  const a = c * Math.cos(hRad);
  const b2 = c * Math.sin(hRad);

  // Convert to LMS
  const lCube = 0.9999999984 * l + 0.3963377921 * a + 0.2158037573 * b2;
  const mCube = 1.0000000088 * l - 0.1055613423 * a - 0.0638541728 * b2;
  const sCube = 1.0000000546 * l - 0.0894841775 * a - 1.2914855480 * b2;

  const lCubed = lCube * lCube * lCube;
  const mCubed = mCube * mCube * mCube;
  const sCubed = sCube * sCube * sCube;

  // Convert to XYZ
  const x = +4.0767416621 * lCubed - 3.3077115913 * mCubed + 0.2309699292 * sCubed;
  const y = -1.2684380046 * lCubed + 2.6097574011 * mCubed - 0.3413193965 * sCubed;
  const z = -0.0041960863 * lCubed - 0.7034186147 * mCubed + 1.7076147010 * sCubed;

  // Convert to linear RGB
  const linearR = +3.2409699419 * x - 1.5373831776 * y - 0.4986107603 * z;
  const linearG = -0.9692436363 * x + 1.8759675015 * y + 0.0415550574 * z;
  const linearB = +0.0556300797 * x - 0.2039769589 * y + 1.0569715142 * z;

  // Convert to sRGB
  const r = linearR <= 0.0031308 ? 12.92 * linearR : 1.055 * (linearR ** (1/2.4)) - 0.055;
  const g = linearG <= 0.0031308 ? 12.92 * linearG : 1.055 * (linearG ** (1/2.4)) - 0.055;
  const b = linearB <= 0.0031308 ? 12.92 * linearB : 1.055 * (linearB ** (1/2.4)) - 0.055;

  // Clamp values between 0 and 1
  const rClamped = Math.max(0, Math.min(1, r));
  const gClamped = Math.max(0, Math.min(1, g));
  const bClamped = Math.max(0, Math.min(1, b));

  // Convert to 8-bit integers and then hex
  const rHex = Math.round(rClamped * 255).toString(16).padStart(2, '0');
  const gHex = Math.round(gClamped * 255).toString(16).padStart(2, '0');
  const bHex = Math.round(bClamped * 255).toString(16).padStart(2, '0');
  const aHex = Math.round(alpha * 255).toString(16).padStart(2, '0');

  return `#${rHex}${gHex}${bHex}${alpha < 1 ? aHex : ''}`;
}