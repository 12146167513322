// Semantic colors

const semanticColorsBase = [
  { hex: "custom", name: "primary" },
  { hex: "#118850", name: "positive" },
  { hex: "#d41433", name: "critical" },
  { hex: "#874CE6", name: "upgrade" },
  { hex: "#CC4D00", name: "warning" },
  { hex: "custom", name: "focus" },
]

// Decorative colors

const decorativeColorsBase = [
  { hex: "#104FC6", name: "blue" },
  { hex: "#b725cb", name: "cyan" },
  { hex: "#05751f", name: "fuchsia" },
  { hex: "#118850", name: "green" },
  { hex: "#ffffff", name: "grey" },
  { hex: "#68718d", name: "light-grey" },
  { hex: "#CC4D00", name: "orange" },
  { hex: "#d12372", name: "pink" },
  { hex: "#874ce6", name: "purple" },
  { hex: "#d41433", name: "red" },
  { hex: "#107a71", name: "teal" },
  { hex: "#a16207", name: "yellow" },
]

// Base colors

export const baseColors = [
  {
    token: "--base",
    light: 0,
    dark: 0,
  },
  {
    token: "--rs-color-background-page",
    light: 0,
    dark: 0,
  },
  {
    token: "--rs-color-background-page-faded",
    light: -0.1,
    dark: -0.1,
  },
  {
    token: "--rs-color-background-elevation-base",
    light: 0,
    dark: 0,
  },
  {
    token: "--rs-color-background-elevation-raised",
    light: +0.1,
    dark: +0.1,
  },
  {
    token: "--rs-color-background-elevation-overlay",
    light: +0.2,
    dark: +0.2,
  },
]

export const textColors = [
  {
    token: "--rs-color-foreground-neutral",
    light: -1,
    dark: +1,
  },
  {
    token: "--rs-color-foreground-neutral-faded",
    light: -0.3,
    dark: +0.3,
  },
  {
    token: "--rs-color-foreground-neutral-placeholder",
    light: -0.2,
    dark: +0.2,
  },
  {
    token: "--rs-color-foreground-disabled",
    light: -0.1,
    dark: +0.1,
  },
]

export const transparentColors = [
  {
    token: "--rs-color-background-neutral",
    light: -0.2,
    dark: +0.25,
  },
  {
    token: "--rs-color-background-neutral-faded",
    light: -0.1,
    dark: +0.15,
  },
  {
    token: "--rs-color-background-neutral-highlighted",
    light: -0.3,
    dark: +0.35,
  },
  {
    token: "--rs-color-background-disabled",
    light: -0.2,
    dark: +0.25,
  },
]

export const borderColors = [
  {
    token: "--rs-color-border-neutral",
    light: -0.5,
    dark: +0.5,
  },
  {
    token: "--rs-color-border-neutral-faded",
    light: -0.25,
    dark: +0.25,
  },
]

export const blurColors = [
  {
    token: "--rs-color-background-blur",
    light: -0,
    dark: +0,
  },
  {
    token: "--rs-color-background-blur-neutral-faded",
    light: -0.1,
    dark: +0.1,
  },
  {
    token: "--rs-color-background-blur-neutral-highlighted",
    light: -0.3,
    dark: +0.3,
  },
]

// Creating tokens

const createSemanticColors = (
  /** @type {string} */ prefix,
  /** @type {string} */ sufix
) => {
  return semanticColorsBase.map((color) => {
    return {
      token: `--rs-color-${prefix}-${color.name}${sufix}`,
      light: 0,
      dark: 0,
      hex: color.hex,
    }
  })
}

const createDecorativeColors = (
  /** @type {string} */ prefix,
  /** @type {string} */ sufix
) => {
  return decorativeColorsBase.map((color) => {
    return {
      token: `--rs-color-${prefix}-decorative-${color.name}${sufix}`,
      light: 0,
      dark: 0,
      hex: color.hex,
    }
  })
}

export const semanticColorsBackground = createSemanticColors("background", "")
export const semanticColorsBackgroundFaded = createSemanticColors(
  "background",
  "-faded"
)
export const semanticColorsBackgroundHighlighted = createSemanticColors(
  "background",
  "-highlighted"
)
export const semanticColorsOnBackground = createSemanticColors(
  "on-background",
  ""
)
export const semanticColorsForeground = createSemanticColors("foreground", "")
export const semanticColorsBorder = createSemanticColors("border", "")
export const semanticColorsBorderFaded = createSemanticColors(
  "border",
  "-faded"
)

export const decorativeColorsBackground = createDecorativeColors(
  "background",
  ""
)
export const decorativeColorsForeground = createDecorativeColors(
  "foreground",
  ""
)
export const decorativeColorsBorder = createDecorativeColors("border", "")
